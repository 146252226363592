<template>
  <div>
 

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end">
             <b-form-input
             id="name"
               v-model="searchName"
               placeholder="Search Name"
               class="searchName mr-1"
               trim
             >
             </b-form-input>
            
              <multiselect
                v-model="searchQuery"
                tag-placeholder="Add this as new tag"
                placeholder="Search or add a tag"
                :hide-selected="true"
                :options="options"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
              ></multiselect>
              <b-button
                class="mr-1 ml-1"
                variant="success"
                :to="{ name: 'forms-element-file-input' }"
              >
                <span class="text-nowrap">Add Candidate</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        :sort-desc.sync="isSortDirDesc"
        responsive="sm"
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        busy.sync="isBusy"
        empty-text="No matching records found"
       
      >
        <template slot="empty">
          <div v-if="loading" class="text-center">
            <b-spinner  variant="primary" label="Loading..."></b-spinner>
          </div>
        </template>
        <!-- Column: User -->
        <template #cell(fullName)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :class="'border_avatar_' + data.item.candidateProfileTypeId"
                :src="data.item.avatarFileUrl"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-edit', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-edit', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">{{ data.item.currentCity }}</small>
            <small class="text-muted">{{
              birthDate(data.item.birthDate)
            }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
         
            <span class="align-text-top text-capitalize">{{
              data.item.role
            }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.statusName)}`"
            class="text-capitalize"
          >
            {{ data.item.statusName }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
          

            <b-button  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-primary"
          class="btn-icon ml-0"
              :to="{ name: 'apps-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle  "></span>
            </b-button>

            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-primary"
          v-b-modal="modalId(data.item.id)" 
          class="btn-icon ml-0" >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle  "></span>
            </b-button>
                     <b-modal :id="'modal' + data.item.id"  title="Confirm Delete?">
                <div class="alert ">
                  Are you sure to remove <b> {{data.item.fullName}}</b> ?
                </div>
                <div slot="modal-footer">
                  <p>
                    <b-button size="md" class="float-left mr-1" variant="secondary" @click="$bvModal.hide('modal' + data.item.id)">
                      <i class="fas fa-times"></i> No
                    </b-button> 
                    <b-button size="md" class="float-right" variant="danger" @click="deleteData(data.item.id)">
                      <i class="fas fa-check"></i> Yes
                    </b-button>
                  </p>
                </div>
              </b-modal>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
      

          <b-col
            cols="12"
            sm="4"
            class="
              d-flex
              align-items-center
              justify-content-center 
            "
          >
            <b-pagination
              v-model="currentPage"
               v-if="!loading"
              @change="fetchItems"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
             <b-col
            cols="12"
            sm="4"
            class="
              d-flex
              align-items-center
              justify-content-center 
            "
          >
            <span class="text-muted"
              > {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} Candidate</span
            >
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<style>
.border_avatar_1 {
  border: 2px solid rgb(58, 250, 0) !important;
}
.border_avatar_2 {
  border: 2px solid rgb(250, 0, 0) !important;
}
.border_avatar_3 {
  border: 2px solid rgb(0, 4, 250) !important;
}
.border_avatar_4 {
  border: 2px solid rgb(146, 146, 146) !important;
}
.searchName{
width: 300px;
}
</style>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import UsersListFilters from "./UsersListFilters.vue";
import useUsersList from "./useUsersList";
import userStoreModule from "../userStoreModule";
import UserListAddNew from "./UserListAddNew.vue";
import Multiselect from "vue-multiselect";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import router from "@/router";
//import Ripple from 'vue-ripple-directive'

export default {
  //   directives: {
  //   Ripple,
  // },
  components: {
    UsersListFilters,
    UserListAddNew,
    VBModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    //BDropdown,
   // BDropdownItem,
    BPagination,
    ToastificationContent,
    vSelect,
    Multiselect,
    BSpinner,
  },

  data() {
    return {
      options: [],
      ren: true,
       ccurrentPage: 7
    };
  },
  mounted() {},
  methods: {
    addTag(newTag) {
      this.refetchData();
      this.options.push(newTag);
      this.searchQuery.push(newTag);
      this.sortBy = "title";
    },
      fetchItems (value) {
      store.commit('appConfig/UPDATE_CURRENTPAGE', value);
   },
 modalId(id){
    return 'modal' + id
    },
    deleteData(id) {
       this.$bvModal.hide('modal' + id)
      store
        .dispatch("app-user/delete", {
          id: id,
        })
        .then((response) => {
         this.refetchData();
          //router.go("/apps/list");
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(error.response.data.errors)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text:value,
            },
          });
          }
        });
    },
    birthDate(birthDate) {
      if (birthDate !== "" && birthDate !== null)
      { var dob = "19800810";
      var year = Number(birthDate.substr(0, 4));
      var month = Number(birthDate.substr(4, 2)) - 1;
      var day = Number(birthDate.substr(6, 2));
      var today = new Date();
      var age = today.getFullYear() - year;
      if (
        today.getMonth() < month ||
        (today.getMonth() == month && today.getDate() < day)
      ) {
        age--;
      }
      return " - " + age;
      }else return " ";
    },
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    const toast = useToast();
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      searchName,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      loading,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useUsersList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      searchName,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      loading,
      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
